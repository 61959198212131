<template>
  <el-card shadow="never">
    <div slot="header">
      <span class="iconfont icon-Currency">&nbsp;通用选项</span>
    </div>
    <el-form :model="form" ref="form" label-width="120px" size="small" style="width:600px">
      <el-form-item label="开机启动类型">
        <el-select v-model="form.boot_up_type" placeholder="请选择" style="width:100%">
          <el-option
            v-for="item in openType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="默认语言">
        <el-select v-model="form.default_language" placeholder="请选择" style="width:100%">
          <el-option
            v-for="item in languageOption"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="前台服务电话"
        prop="server_mobile"
        :rules="[{ pattern:/^(([1][3,4,5,7,8,9]\d{9})|([0]\d{10,11})|(\d{7,8})|(\d{4}|\d{3})-(\d{7,8}))$/,message: '请输入正确的手机号码或者固话号码',trigger: 'blur'}]"
      >
        <el-input v-model="form.server_mobile" placeholder="请输入前台服务电话"></el-input>
        <span>服务电话会显示在APP主界面</span>
      </el-form-item>
      <el-form-item label="APP设置密码"
          prop="app_setting_password"
          :rules="[{ pattern:/^\d{4}$/,message: '请输入4位数字密码，留空表示不设置密码',trigger: 'blur'}]"
      >
        <el-input v-model="form.app_setting_password" placeholder="请输入4位数字密码，留空表示不设置密码" maxlength="4" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="二维码">
        <el-dropdown placement="top">
          <div>
            <div class="avatar-uploader-logo">
              <i class="el-icon-plus avatar-uploader-logo-icon" v-if="!form.qr_url"></i>
              <el-image
                v-else
                :src="fileUrl+form.qr_url"
                style="width: 100px; height: 100px;background:rgba(235,235,235,0.5);cursor: pointer;"
                fit="contain"
              ></el-image>
            </div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="dialogEditFileUpload=true"
              style="padding:0"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
            <el-dropdown-item style="padding:0">
              <el-upload
                style="text-align:center"
                :action="actionUrl"
                accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                :headers="header"
                :data="{type:0,category:'图片'}"
                :show-file-list="false"
                :on-success="handleEditSuccess"
                :before-upload="beforeEditUpload"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>

      <el-form-item label="APP升级">
        <el-radio-group v-model="form.app_upgrade_switch" size="mini">
          <el-radio-button label="on">开启</el-radio-button>
          <el-radio-button label="off">关闭</el-radio-button>
        </el-radio-group>
        <p>打开此开关app启动时会检测是否有新版本</p>
      </el-form-item>
      <el-form-item label="自动清理缓存">
        <el-radio-group v-model="form.automatic_clean" size="mini">
          <el-radio-button label="on">开启</el-radio-button>
          <el-radio-button label="off">关闭</el-radio-button>
        </el-radio-group>
        <p>终端空闲时自动清理系统缓存文件默认开机10分钟自动清理</p>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" style="width:100px" @click="onSubmitBasic">保存</el-button>
      </el-form-item>
    </el-form>

    <el-dialog
      :visible.sync="dialogEditFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="true" :type="0" :category="'图片'" v-if="dialogEditFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditLogo" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import {
  getCurrencyApi,
  editCurrencyApi,
  getLanguageApi
} from "../../api/data";
import Aes from "../../utils/aes";
import FileUpload from "../../components/FileUpload";
export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      form: {
        app_setting_password: "",
        app_upgrade_switch: "",
        automatic_clean: "",
        boot_up_type: "",
        default_language: "",
        server_mobile: "",
        qr_url: "",
        groupId: ""
      },
      openType: [
        { value: "main_interface", label: "主界面" },
        { value: "live", label: "直播" },
        { value: "custom", label: "自定义" }
      ],
      languageOption: [],
      dialogEditFileUpload: false //图片窗口
    };
  },
  computed: {
    //当前场所信息
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    },
    header() {
      //上传头部POST请求头部带token信息验证登录状态
      return {
        accessToken: this.$store.state.user.authorization
      };
    },
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    },
    //上传路径
    actionUrl() {
      return this.$store.state.fileUrl + "/file/upload";
    }
  },
  created() {
    this.initLoad();
    this.initLanguage();
  },
  methods: {
    async initLoad() {
      await getCurrencyApi({ groupId: this.placesInfo.groupId }).then(res => {
        console.log(res);
        if (res) this.form = res.data;
      });
    },
    //初始化语言
    initLanguage() {
      getLanguageApi({ groupId: this.placesInfo.groupId }).then(res => {
        if (res) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].id = res.data[i].id.toString();
          }
        }
        this.languageOption = res.data;
      });
    },
    //保存通用选项
    onSubmitBasic() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.form.groupId = this.placesInfo.groupId;
          editCurrencyApi(this.form).then(res => {
            if (res) this.$message.success(res.msg);
          });
        } else {
          return false;
        }
      });
    },
    //图片窗口关闭事件
    closeDialogFileUpload() {
      this.$store.commit("setFileList", []);
    },
    //修改logo
    onSubmitEditLogo() {
      this.dialogEditFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        this.form.qr_url = this.$store.state.fileList[0].filePath;
        this.$store.commit("setFileList", []);
      }
    },
    //上传完成事件
    handleEditSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.form.qr_url = result.msg;
      } else {
        this.$message.error(result.msg);
      }
      this.initLoad();
    },
    //上传前事件
    beforeEditUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    setPassword(e) {
      console.log(e);
      this.form.app_setting_password = e;
    }
  }
};
</script>
<style lang='scss'>
.avatar-uploader-logo {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
}
.avatar-uploader-logo:hover {
  border-color: #5e72f9;
}
.avatar-uploader-logo-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
</style>